.contact-form {
  width: 70%;
  margin-top: 30px;
  margin-left: 15%;
  text-align: center;

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: rgb(184, 219, 247);
    height: 50px;
    font-size: 16px;
    color: black;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 9px;
    &:focus { 
      outline: none !important;
    }
  }

  textarea {
    width: 100%;
    border: 0;
    background: rgb(184, 219, 247);
    height: 50px;
    font-size: 16px;
    color: black;
    padding: 20px;
    box-sizing: border-box;
    resize: none;
    min-height: 150px;
    border-radius: 9px;
    &:focus { 
      outline: none !important;
    }
  }

  ::placeholder{
    color: white;
		font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .flat-button {
    color: #1e56a0;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 10px 34px;
    border: 1px solid #1e56a0;
    border-radius: 9px;
    background: 0 0;
    text-transform: uppercase;
    background-color: white;
    -webkit-transition: background-color 1000ms linear;
    -moz-transition: background-color 1000ms linear;
    -o-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
  }

  .flat-button:hover{
    background-color: rgb(184, 219, 247);
  }
}
#contact{
  padding-bottom: 5%;
}